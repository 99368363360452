<template>
  <div class="help-center">
    <div class="help-header family">帮助中心</div>
    <div class="help-main family">
      <button>如何绑定steam</button>
      <button>steam交易限制</button>
    </div>
    <div class="help-footer">
      <div class="help-title">提货帮助</div>
      <div class="help-content">
        <p>1、背包状态介绍</p>
        <p>
          &emsp;&emsp;本csgo开箱网背包状态分为四种，分别为（暂未发货、退回、请去提货、领取成功）暂未发货 ：代表您已提交，网站管理员暂未处理发货或因缺货等原因暂未协调到货源，此状态需要您耐心等待。退回
          ：代表因市场无货，所以无法处理您的提货请求。或者因为您背包物品7天未取，而价格波动太大，导致无法完成您的提货需求。此状态需要您回收网站或进行合成升级请去提货
          ：代表您的提交已通过审核，并且网站已经发货，此状态需要您及时去steam平台领取您的装备。领取成功 ：代表您steam装备已经领取完成。
        </p>
        <p>2、提取装备前需要的准备</p>
        <p>
          &emsp;&emsp;提取装备前请去 个人中心 页面设置您的steam交易报价链接。打不开Steam怎么办? 点击这里。  
          获取Steam交易链接详情步骤:>>启动steam>>左上角名字>》库存》>交易报价>》谁能向我发送交易报价
          为了拒绝羊毛党，提货前确认您近三四天有充值记录。否则提货功能一概关闭。
        </p>
        <p>3、发货时间因装备价格波动、库存等原因。</p>
        <p>&emsp;&emsp;正常状态下，您将于1分钟内获取到取货通知，特殊情况下，取货时间最长为18小时。还请耐心等待。</p>
        <p>
          4、发货时间有其他任何疑问，请随时联系官方客服（10：00-21：00）
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "helpcenter",
};
</script>

<style lang="scss" scoped>
.help-center {
  margin-top: 50px;

  .help-header {
    height: 44px;
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 1px #9fda35;
    font-size: 30px;
  }

  .help-main {
    display: flex;
    justify-content: space-between;
    margin: 25px auto;
    width: 245px;
    height: 40px;

    button {
      width: 110px;
      height: 40px;
      background: linear-gradient(125deg, #009fff 0%, #05377b 100%);
      border: 3px solid RGBA(0, 153, 248, 1);
      box-shadow: 1px 1px 3px 0px #000 inset;
      border-radius: 20px;
      font-size: 12px;
      color: #009fff;
    }
  }

  .help-footer {
    width: 326px;
    padding-left: 24px;
    padding-top: 24px;
    margin: auto;
    background: linear-gradient(317deg, #05377b 0%, #009fff 99%, #009fff 100%);
    border-radius: 20px;

    .help-title {
      color: RGBA(255, 204, 0, 1);
      font-size: 26px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
    }

    .help-content {
      margin-top: 10px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    }
  }
}
</style>